import type { TWebsiteConfig } from '~/typings/types/website-config.types';
import useDialogs from '~/composables/organisms/useDialogs';

/**
 * @description Form
 */
export type TForm = {
  name: string;
  title: string;
  subtitle: string;
  component: string;
};

/**
 * @description Form list
 */
export type TFormList = {
  [key: string]: TForm;
};

/**
 * @description Form names
 */
export enum EFormNames {
  FORM_CASHBACK = 'FORM_CASHBACK',
  FORM_CALLMASTER = 'FORM_CALLMASTER',
  FORM_OTK = 'FORM_OTK',
  FORM_FAQ_QUESTION = 'FORM_FAQ_QUESTION',
  FORM_REQUEST_COURSE_MATERIALS = 'FORM_REQUEST_COURSE_MATERIALS',
}

export interface IUseForms {
  /**
   * @description Список доступных на сайте форм.
   */
  formList: TFormList;
  /**
   * @description Функция заносит в state необходимую форму, далее эту форму можно асинхронно подгрузить в нужном месте.
   * @param {TForm} form - Данные формы из formList
   */
  requireForm(form: TForm): void;
}

/**
 * @description Composable функция для работы с формами на сайте.
 * @returns {IUseForms}
 */
export default function useForms(): IUseForms {
  const websiteConfig = useState<TWebsiteConfig>('config');
  const { openFormDialog } = useDialogs();

  const formList = {
    FORM_CASHBACK: {
      name: 'Cashback',
      title: 'Данные для получения кэшбэка',
      subtitle: 'Форма обязательна к заполнению!',
      component: 'MFormCashback',
    },
    FORM_CALLMASTER: {
      name: 'Вызов мастера',
      title: 'Нужен ремонт?',
      subtitle: 'Оставьте заявку, мы с Вами свяжемся',
      component: 'MFormCallMaster',
    },
    FORM_OTK: {
      name: 'Отдел техничекского контроля',
      title: 'Написать отзыв',
      subtitle: 'Расскажите о ваших впечатлениях',
      component: 'MFormOTK',
    },
    FORM_FAQ_QUESTION: {
      name: 'Вопросы',
      title: 'Остались вопросы?',
      subtitle: 'Оставьте заявку, специалист с вами свяжется',
      component: 'MFormFAQQuestion',
    },
    FORM_REQUEST_COURSE_MATERIALS: {
      name: 'Запрос на получение материалов курсов',
      title: 'Получить материалы курса',
      subtitle: 'Видео, презентации, подробная программа',
      component: 'MFormRequestCourseMaterials',
    },
  };

  function requireForm(form: TForm) {
    openFormDialog();
    websiteConfig.value.dialogs.forms.requiredForm = form;
  }

  return {
    formList,
    requireForm,
  };
}
